module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com"],"web":[{"name":"Noto Sans","file":"https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-32953CWSN4","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/LCMS_logo.png","name":"Trinity Evangelical Lutheran Church","short_name":"Trinity Waterville","background_color":"#005DA6","theme_color":"#005DA6","display":"standalone","description":"A traditional, liturgical congregation of the Lutheran Church Missouri Synod","icons":[{"src":"./android-chrome-512x512.png","type":"image/png","sizes":"512x512"},{"src":"./android-chrome-192x192.png","type":"image/png","sizes":"192x192"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc0a835e38ac2b634c716cd933f54cbb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/bulletins/","/catechesis/","/contact/","/contact/","/events/","/index/","/links/","/privacy-policy/","/sacred-art/","/sermons/","/sermons/","/staff/","/terms-and-conditions/","/what-to-expect/","/what-we-believe/","/who-we-are/","/worship-schedule/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
